F
<template>
  <div>
    <div class="md-layout">
      <div class="md-layout-item">
        <div class="btn-row">
          <md-button class="md-success" @click="newUnitCostsWork">
            {{ $t('button.add') }}
          </md-button>
        </div>
        <md-card>
          <md-card-header class="md-card-header-icon md-card-header-green">
            <div class="card-icon">
              <md-icon>list</md-icon>
            </div>
            <h4 class="title">{{ $t('label.unit_costs_works') }}</h4>
          </md-card-header>
          <md-card-content>
            <md-table
                :value="queriedData"
                :md-sort.sync="currentSort"
                :md-sort-order.sync="currentSortOrder"
                :md-sort-fn="customSort"
                class="paginated-table table-striped table-hover"
            >
              <md-table-toolbar>
                <md-field>
                  <label for="pages">{{ $t('label.per_page') }}</label>
                  <md-select
                      :value="pagination.perPage"
                      name="pages"
                      id="pages"
                      @input='onChangePerPage'
                  >
                    <md-option
                        v-for="item in pagination.perPageOptions"
                        :key="item"
                        :label="item"
                        :value="item"
                    >
                      {{ item === 'all' ? $t(`pagination.all`) : item }}
                    </md-option>
                  </md-select>
                </md-field>

                <md-field>
                  <md-input
                      type="search"
                      class="mb-3"
                      clearable
                      style="width: 200px"
                      :placeholder="$t('label.search_records')"
                      v-model="searchQuery"
                  ></md-input>
                </md-field>
              </md-table-toolbar>

              <md-table-row slot="md-table-row" slot-scope="{item}">
                <md-table-cell :md-label="$t('muc.work_asset_type')" md-sort-by="work_asset_type">
                  {{ $t(`muc.${getAssetNameById(item.work_asset_type)}`) }}
                </md-table-cell>
                <md-table-cell :md-label="$t('muc.work_name')" md-sort-by="work_name">
                  {{ $t(`muc.work_${item.work_name}`) }}
                </md-table-cell>
                <md-table-cell :md-label="$t('muc.work_maintenance_type')" md-sort-by="work_maintenance_type">
                  {{ $t(`muc.${getMaintenanceNameById(item.work_maintenance_type)}`) }}
                </md-table-cell>
                <md-table-cell :md-label="$t('muc.element_name')" md-sort-by="element_name">
                  {{ item.element_name }}
                </md-table-cell>
                <md-table-cell :md-label="$t('muc.work_unit')" md-sort-by="work_unit">
                  {{ item.work_unit }}
                </md-table-cell>
                <md-table-cell :md-label="$t('muc.work_fin_cost')" md-sort-by="work_fin_cost">
                  {{ item.work_fin_cost }}
                </md-table-cell>
                <md-table-cell :md-label="$t('muc.work_econom_cost')" md-sort-by="work_econom_cost">
                  {{ item.work_econom_cost }}
                </md-table-cell>
                <md-table-cell
                    class="btn-cont"
                    :md-label="$t('tables.actions')"
                >
                  <div class="cell-actions">
                    <md-button
                        class="md-raised md-sm md-primary"
                        @click.stop.prevent="onEditRow(item.work_id)"
                    >
                      {{ $t('button.edit') }}
                    </md-button>
                    <md-button
                        class="md-raised md-sm md-info"
                        @click.stop.prevent="onEditRowCriteria(item.work_id)"
                    >
                      {{ $t('button.criteria') }}
                    </md-button>

                  </div>
                </md-table-cell>
              </md-table-row>
            </md-table>
            <div class="footer-table md-table">
              <table>
                <tfoot>
                <tr>
                  <th
                      v-for="item in footerTable"
                      :key="item.name"
                      class="md-table-head"
                  >
                    <div
                        class="md-table-head-container md-ripple md-disabled"
                    >
                      <div class="md-table-head-label">{{ item }}</div>
                    </div>
                  </th>
                </tr>
                </tfoot>
              </table>
            </div>
          </md-card-content>
          <md-card-actions md-alignment="space-between">
            <div class>
              <p class="card-category">
                {{
                  $t('label.showing_from_to_of_entries', {
                    from: to === 0 ? 0 : from + 1,
                    to: to,
                    total
                  })
                }}
              </p>
            </div>
            <pagination
                class="pagination-no-border pagination-success"
                v-model="pagination.currentPage"
                :per-page="pagination.perPage"
                :total="total"
            ></pagination>
          </md-card-actions>
        </md-card>
      </div>
    </div>
  </div>
</template>

<script>

import {Pagination} from '@/components'
// import {SlideYDownTransition} from 'vue2-transitions'
// import Fuse from 'fuse.js'

export default {
  name: "mucWorkList",
  components: {
    Pagination
  },
  data() {
    return {
      mid: this.$route.params.mid,
      mucWorkList: [],
      maintenanceTypes: [],
      assetTypes: [],
      pagination: {
        perPage: 10,
        currentPage: 1,
        perPageOptions: [5, 10, 25, 50, 'all'],
        total: 0
      },
      currentSort: 'key',
      currentSortOrder: 'asc',
      searchQuery: '',
      propsToSearch: ['key'],
      searchedData: [],
      fuseSearch: null,
      footerTable: []
    }
  },
  async created() {
    this.maintenanceTypes = await this.$store.dispatch("LOAD_MAINTENANCE_TYPE_LIST")
    this.assetTypes = await this.$store.dispatch("LOAD_ASSET_TYPE_LIST")
    this.mucWorkList = await this.$store.dispatch("LOAD_UNIT_COSTS_WORKS", this.mid)
  },
  methods: {
    onChangePerPage(val) {
      const limit = val === 'all' ? this.total : val
      this.pagination.perPage = limit
    },
    getMaintenanceNameById(id) {
      let type = this.maintenanceTypes.find(type => type.id === id)
      return type ? type.name : 'undefined'
    },
    getAssetNameById(id) {
      let type = this.assetTypes.find(type => type.id === id)
      return type ? type.name : 'undefined'
    },
    newUnitCostsWork() {
      this.$router.push({name: "unit-costs-works-add"})
    },
    onEditRow(work_id) {
      this.$router.push({name: "unit-costs-works-upd", params: {mid: this.mid, work_id: Number(work_id)}})
    },
    onEditRowCriteria(work_id) {
      this.$router.push({name: "unit-costs-works-criteria", params: {mid: this.mid, work_id: Number(work_id)}})
    },
    customSort(value) {
      return value.sort((a, b) => {
        const sortBy = this.currentSort
        if (this.currentSortOrder === 'asc') {
          return a[sortBy]
              .toString()
              .localeCompare(b[sortBy].toString(), undefined, {numeric: true})
        }
        return b[sortBy]
            .toString()
            .localeCompare(a[sortBy].toString(), undefined, {numeric: true})
      })
    }
  },
  computed: {
    queriedData() {
      const result = !this.searchQuery
          ? this.tableData
          : this.searchedData
              ? this.searchedData
              : []
      return result.slice(this.from, this.to)
    },
    to() {
      let highBound = this.from + this.pagination.perPage
      if (this.total < highBound) {
        highBound = this.total
      }
      return highBound
    },
    from() {
      return this.pagination.perPage * (this.pagination.currentPage - 1)
    },
    total() {
      return this.searchQuery ? this.searchedData.length : this.tableData.length
    },
    tableData() {
      return this.mucWorkList
    }
  },
  watch: {
    /**
     * Searches through the table data by a given query.
     * NOTE: If you have a lot of data, it's recommended to do the search on the Server Side and only display the results here.
     * @param value of the query
     */
    searchQuery(value) {
      let result = this.tableData
      const regex = new RegExp(`${value}`, 'i')
      if (value !== '') {
        result = this.tableData.filter((row) => {
          return Object.values(row).reduce((incl, elem) => incl || regex.test(elem), false)
        }) //this.fuseSearch.search(this.searchQuery)
      }
      this.searchedData = result
    }
    // bridges_headers() {
    // }
  }
}

</script>

<style lang="scss" scoped>

.cell-actions {
  display: flex;
  flex-direction: column;
  align-items: flex-end;

  .md-button {
    margin: 3px 0;
    min-width: 100px;
  }
}

.btn-row {
  width: 100%;
  display: flex;
  justify-content: flex-end;
}
</style>
